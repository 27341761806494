@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  font-family: 'Josefin Sans', cursive;
  color: #021a48;
}

.App a {
  color: #021a48;
}

.App a:hover {
  color: #ff6700;
}

.logo img {
  max-width: 300px;
  max-height: 300px;
}

ul > li {
  display: inline;
  padding: 3px
}

ul > li:not(:last-child)::after {
  content: "|";
  margin: 0 .25em;
}

.email {
  font-size: 12px;
}
