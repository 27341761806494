@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

.App {
  text-align: center;
  font-family: 'Josefin Sans', cursive;
  color: #021a48;
}

.App a {
  color: #021a48;
}

.App a:hover {
  color: #ff6700;
}

.logo img {
  max-width: 300px;
  max-height: 300px;
}

ul > li {
  display: inline;
  padding: 3px
}

ul > li:not(:last-child)::after {
  content: "|";
  margin: 0 .25em;
}

.email {
  font-size: 12px;
}